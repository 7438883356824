import { FunctionComponent, useCallback } from "react";
import Tsg from "./Tsg";

export type PartnersAndAmbassadorsType = {
  className?: string;
};

const PartnersAndAmbassadors: FunctionComponent<PartnersAndAmbassadorsType> = ({
  className = "",
}) => {
  const onActionBtnContainerClick = useCallback(() => {
    // Please sync "List with us/Landlords" to the project
  }, []);

  const onActionBtnContainerClick1 = useCallback(() => {
    // Please sync "404 page" to the project
  }, []);

  return (
    <section
      className={`self-stretch bg-white overflow-hidden flex flex-row items-end justify-start pt-[92px] px-16 pb-[109px] box-border gap-16 shrink-0 max-w-full z-[5] mt-[-1.4px] text-left text-13xl text-white font-sora mq750:gap-8 mq750:pl-8 mq750:pr-8 mq750:box-border mq1100:pb-[71px] mq1100:box-border mq1275:flex-wrap mq450:gap-4 mq450:pb-[46px] mq450:box-border ${className}`}
    >
      <div className="h-[574px] flex-1 rounded-21xl overflow-hidden flex flex-col items-start justify-end py-8 px-6 box-border bg-[url('/public/landlords@3x.png')] bg-cover bg-no-repeat bg-[top] max-w-full mq750:min-w-full mq1275:min-h-[auto]">
        <div className="w-[344px] flex flex-col items-start justify-end py-0 pl-0 pr-[47px] box-border gap-5 max-w-full mq450:pr-5 mq450:box-border">
          <div className="self-stretch flex flex-row items-start justify-start">
            <h1 className="m-0 flex-1 relative text-inherit font-semibold font-[inherit] [text-shadow:0px_4px_4px_rgba(0,_0,_0,_0.25)] mq750:text-7xl mq450:text-lgi">
              Landlords, List with Unistay
            </h1>
          </div>
          <Tsg
            onActionBtnContainerClick={onActionBtnContainerClick}
            propPosition="unset"
            propTop="unset"
            propLeft="unset"
            propBackgroundColor="#fff"
            propPadding="16px 63px 16px 64px"
            propBorderRadius="5px"
            propFlex="unset"
            comingSoon="Learn more"
            propColor="#1d1d1d"
            propTextDecoration="none"
            propDisplay="unset"
            propMinWidth="unset"
          />
        </div>
      </div>
      <div className="h-[592px] w-[449px] relative max-w-full mq750:min-w-full mq1275:flex-1">
        <div className="absolute top-[18px] left-[0px] w-full flex flex-row items-start justify-start max-w-full">
          <div className="flex-1 rounded-21xl flex flex-col items-start justify-end pt-[415px] pb-11 pl-[25px] pr-[26px] box-border relative gap-5 bg-[url('/public/ambassadors@3x.png')] bg-cover bg-no-repeat bg-[top] max-w-full">
            <h1 className="m-0 relative text-inherit font-semibold font-[inherit] [text-shadow:0px_4px_4px_rgba(0,_0,_0,_0.25)] mq750:text-7xl mq450:text-lgi">
              Become an Ambassador
            </h1>
            <div className="self-stretch flex flex-row items-start justify-start py-0 px-[82px] mq450:pl-5 mq450:pr-5 mq450:box-border">
              <Tsg
                onActionBtnContainerClick={onActionBtnContainerClick1}
                propPosition="unset"
                propTop="unset"
                propLeft="unset"
                propBackgroundColor="#fff"
                propPadding="16px 63px 16px 64px"
                propBorderRadius="5px"
                propFlex="1"
                comingSoon="Learn more"
                propColor="#1d1d1d"
                propTextDecoration="none"
                propDisplay="inline-block"
                propMinWidth="105px"
              />
            </div>
            <div className="!m-[0] absolute top-[-18px] right-[49px] shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] bg-blue flex flex-row items-start justify-start py-1.5 px-[34px] whitespace-nowrap z-[1] text-lg">
              <div className="relative font-semibold">Coming soon</div>
            </div>
          </div>
        </div>
        <Tsg comingSoon="Coming soon" />
      </div>
    </section>
  );
};

export default PartnersAndAmbassadors;
