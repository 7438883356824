import { FunctionComponent } from "react";
import FindAccommodation from "./FindAccommodation";
import HowItWorks1 from "./HowItWorks1";
import ResourcesDropdown from "./ResourcesDropdown";
import GetStarted from "./GetStarted";
import SignIn from "./SignIn";

export type NavBarNewUserloggedOutType = {
  className?: string;
};

const NavBarNewUserloggedOut: FunctionComponent<NavBarNewUserloggedOutType> = ({
  className = "",
}) => {
  return (
    <header
      className={`self-stretch bg-white flex flex-row items-center justify-between py-[17px] px-16 box-border shrink-0 top-[0] z-[99] sticky max-w-full gap-5 text-left text-sm text-black font-sora mq750:pl-8 mq750:pr-8 mq750:box-border ${className}`}
    >
      <img
        className="h-11 w-[127.6px] relative overflow-hidden shrink-0"
        loading="lazy"
        alt=""
        src="/new-logo-1.svg"
      />
      <div className="flex flex-row items-center justify-end py-0 pl-[143px] pr-0 box-border gap-20 max-w-full mq1100:gap-10 mq1100:pl-[71px] mq1100:box-border mq450:gap-5 mq450:pl-[35px] mq450:box-border">
        <div className="flex flex-row items-center justify-center gap-[45px] max-w-full mq1100:hidden mq450:gap-[22px]">
          <FindAccommodation />
          <HowItWorks1 />
          <ResourcesDropdown />
        </div>
        <div className="flex flex-row items-center justify-start gap-8 text-white">
          <div className="rounded-8xs bg-blue flex flex-row items-center justify-center py-3 px-[23px]">
            <GetStarted />
          </div>
          <div className="rounded-8xs flex flex-row items-center justify-center py-3.5 px-[7px] text-black">
            <SignIn />
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavBarNewUserloggedOut;
