import { FunctionComponent } from "react";
import Resources from "./Resources";
import Arrow from "./Arrow";

export type ResourcesDropdownType = {
  className?: string;
};

const ResourcesDropdown: FunctionComponent<ResourcesDropdownType> = ({
  className = "",
}) => {
  return (
    <div
      className={`flex flex-row items-center justify-center gap-1.5 text-left text-sm text-black font-sora ${className}`}
    >
      <Resources />
      <Arrow />
    </div>
  );
};

export default ResourcesDropdown;
