import { FunctionComponent, useMemo, type CSSProperties } from "react";

export type SolutionType = {
  className?: string;
  headset?: string;
  customerSupport?: string;
  enjoyRoundTheClockCustomer?: string;

  /** Style props */
  propPadding?: CSSProperties["padding"];
};

const Solution: FunctionComponent<SolutionType> = ({
  className = "",
  propPadding,
  headset,
  customerSupport,
  enjoyRoundTheClockCustomer,
}) => {
  const solution2Style: CSSProperties = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  return (
    <div
      className={`self-stretch flex flex-col items-start justify-center gap-[18px] text-left text-xl text-black font-sora ${className}`}
      style={solution2Style}
    >
      <img
        className="w-10 h-10 relative object-cover"
        loading="lazy"
        alt=""
        src={headset}
      />
      <div className="relative font-semibold mq450:text-base">
        {customerSupport}
      </div>
      <div className="self-stretch relative text-base">
        {enjoyRoundTheClockCustomer}
      </div>
    </div>
  );
};

export default Solution;
