import { FunctionComponent } from "react";

export type HeaderType = {
  className?: string;
};

const Header: FunctionComponent<HeaderType> = ({ className = "" }) => {
  return (
    <div
      className={`self-stretch flex flex-row items-start justify-start flex-wrap content-start pt-0 px-0 pb-0 box-border gap-x-0 gap-y-8 max-w-full ${className}`}
    >
      <div className="flex-1 flex flex-row items-center justify-between min-w-[1246px] max-w-full gap-5 mq1275:min-w-full mq450:flex-wrap">
        <img
          className="h-[51px] w-[148px] relative overflow-hidden shrink-0"
          alt=""
          src="/new-logo-1-1.svg"
        />
        <div className="flex flex-row items-center justify-start gap-7">
          <img
            className="h-10 w-10 relative overflow-hidden shrink-0"
            loading="lazy"
            alt=""
            src="/icroundfacebook.svg"
          />
          <img
            className="h-10 w-10 relative overflow-hidden shrink-0"
            loading="lazy"
            alt=""
            src="/riinstagramline.svg"
          />
          <img
            className="h-[35px] w-[35px] relative overflow-hidden shrink-0"
            loading="lazy"
            alt=""
            src="/formkittwitter.svg"
          />
          <img
            className="h-[30px] w-[30px] relative overflow-hidden shrink-0"
            loading="lazy"
            alt=""
            src="/simpleiconslinkedin.svg"
          />
        </div>
      </div>
      <img
        className="w-[1312px] relative max-h-full max-w-full"
        loading="lazy"
        alt=""
        src="/divider.svg"
      />
    </div>
  );
};

export default Header;
