import { FunctionComponent } from "react";
import NavBarNewUserloggedOut from "../components/NavBarNewUserloggedOut";
import Hero from "../components/Hero";
import HowItWorks from "../components/HowItWorks";
import ListingsShowcaseC from "../components/ListingsShowcaseC";
import WhyUs from "../components/WhyUs";
import PartnersAndAmbassadors from "../components/PartnersAndAmbassadors";
import FooterSection from "../components/FooterSection";

const LandingPage: FunctionComponent = () => {
  return (
    <div className="w-full relative flex flex-col items-start justify-start pt-0 px-0 pb-[48.5px] box-border leading-[normal] tracking-[normal]">
      <main className="self-stretch flex flex-col items-start justify-start max-w-full">
        <NavBarNewUserloggedOut />
        <Hero />
        <HowItWorks />
        <ListingsShowcaseC />
        <WhyUs />
        <PartnersAndAmbassadors />
      </main>
      <FooterSection />
    </div>
  );
};

export default LandingPage;
