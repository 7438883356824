import { FunctionComponent } from "react";

export type SearchIconType = {
  className?: string;
};

const SearchIcon: FunctionComponent<SearchIconType> = ({ className = "" }) => {
  return (
    <div
      className={`flex flex-row items-start justify-start relative gap-[6.9px] ${className}`}
    >
      <div className="h-[34.4px] w-[34.4px] relative rounded-[50%] bg-blue" />
      <img
        className="h-[17.2px] w-[17.2px] absolute !m-[0] top-[8.3px] left-[8.3px] overflow-hidden shrink-0 z-[1]"
        alt=""
        src="/ionsearchsharp.svg"
      />
    </div>
  );
};

export default SearchIcon;
