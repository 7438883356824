import { FunctionComponent, useCallback } from "react";
import PropertyListing from "./PropertyListing";

export type ListingsShowcaseCType = {
  className?: string;
};

const ListingsShowcaseC: FunctionComponent<ListingsShowcaseCType> = ({
  className = "",
}) => {
  const onButtonContainerClick = useCallback(() => {
    // Please sync "Listings/Search" to the project
  }, []);

  return (
    <section
      className={`self-stretch bg-white overflow-hidden flex flex-col items-end justify-start pt-[78px] pb-[138px] pl-16 pr-[71px] box-border gap-[102px] shrink-0 max-w-full z-[3] mt-[-1.4px] text-center text-21xl text-black font-sora mq750:gap-[51px] mq750:pl-8 mq750:pr-[35px] mq750:pb-[58px] mq750:box-border mq1275:pb-[90px] mq1275:box-border mq450:gap-[25px] ${className}`}
    >
      <div className="self-stretch flex flex-col items-end justify-start gap-[50px] max-w-full mq750:gap-[25px]">
        <div className="self-stretch flex flex-row items-start justify-center py-0 pl-7 pr-5 box-border max-w-full">
          <div className="w-[1055px] flex flex-col items-center justify-start gap-[45px] max-w-full mq750:gap-[22px]">
            <h1 className="m-0 w-[602px] relative text-inherit font-semibold font-[inherit] inline-block max-w-full mq750:text-13xl mq450:text-5xl">
              Browse Our Featured Listings
            </h1>
            <div className="self-stretch flex flex-row items-start justify-between gap-5 text-left text-base text-blue mq750:flex-wrap mq750:justify-center">
              <div className="flex flex-row items-start justify-start py-0 pl-2 pr-[7px]">
                <div className="relative inline-block min-w-[50px]">Lagos</div>
              </div>
              <div className="flex flex-row items-start justify-start">
                <div className="relative [text-decoration:underline]">
                  Enugu
                </div>
              </div>
              <div className="flex flex-row items-start justify-start">
                <div className="relative">Awka</div>
              </div>
              <div className="flex flex-row items-start justify-start">
                <div className="relative">Benin</div>
              </div>
              <div className="flex flex-row items-start justify-start">
                <div className="relative inline-block min-w-[57px]">Owerri</div>
              </div>
              <div className="flex flex-row items-start justify-start">
                <div className="relative">Abeokuta</div>
              </div>
              <div className="flex flex-row items-start justify-start">
                <div className="relative inline-block min-w-[37px]">Ekiti</div>
              </div>
              <div className="flex flex-row items-start justify-start">
                <div className="relative inline-block min-w-[49px]">Abuja</div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start flex-wrap content-start gap-x-[31.7px] gap-y-[50px] text-left text-lg mq750:gap-4">
          <PropertyListing propertyImage="/property-image@2x.png" />
          <PropertyListing propertyImage="/property-image@2x.png" />
          <PropertyListing propertyImage="/property-image@2x.png" />
          <PropertyListing propertyImage="/property-image@2x.png" />
          <PropertyListing propertyImage="/property-image@2x.png" />
          <PropertyListing propertyImage="/property-image@2x.png" />
          <PropertyListing propertyImage="/property-image@2x.png" />
          <PropertyListing propertyImage="/property-image@2x.png" />
        </div>
      </div>
      <div className="self-stretch flex flex-row items-start justify-end py-0 pl-[553px] pr-[546px] text-left text-base text-white mq750:pl-[138px] mq750:pr-[136px] mq750:box-border mq1275:pl-[276px] mq1275:pr-[273px] mq1275:box-border mq450:pl-5 mq450:pr-5 mq450:box-border">
        <div
          className="flex-1 rounded-8xs bg-blue flex flex-row items-start justify-start py-vertical-button-spacing pl-horizontal-button-spacing pr-[63px] cursor-pointer"
          onClick={onButtonContainerClick}
        >
          <div className="relative font-semibold inline-block min-w-[79px]">
            See more
          </div>
        </div>
      </div>
    </section>
  );
};

export default ListingsShowcaseC;
