import { FunctionComponent, useCallback } from "react";

export type FooterLinksType = {
  className?: string;
};

const FooterLinks: FunctionComponent<FooterLinksType> = ({
  className = "",
}) => {
  const onAboutUsTextClick = useCallback(() => {
    // Please sync "404 page" to the project
  }, []);

  const onHowItWorksClick = useCallback(() => {
    // Please sync "How it works - tenants" to the project
  }, []);

  const onHowItWorksClick1 = useCallback(() => {
    // Please sync "List with us/Landlords" to the project
  }, []);

  const onUnistayPlusTextClick = useCallback(() => {
    // Please sync "Unistay plus" to the project
  }, []);

  const onContactTextClick = useCallback(() => {
    // Please sync "Contact us" to the project
  }, []);

  return (
    <div
      className={`self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-0 gap-[102px] text-left text-xl text-black font-sora mq750:gap-[51px] mq450:gap-[25px] ${className}`}
    >
      <div className="self-stretch flex flex-row items-start justify-between gap-5 mq750:flex-wrap">
        <div className="flex flex-col items-start justify-start gap-6">
          <div className="relative font-semibold mq450:text-base">Company</div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[13px] text-sm text-dark-gray">
            <div
              className="self-stretch relative cursor-pointer"
              onClick={onAboutUsTextClick}
            >
              About us
            </div>
            <div className="relative hidden">How it works</div>
            <div
              className="self-stretch relative cursor-pointer"
              onClick={onAboutUsTextClick}
            >
              Partners
            </div>
            <div
              className="self-stretch relative cursor-pointer"
              onClick={onAboutUsTextClick}
            >
              Careers
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-6">
          <div className="self-stretch relative font-semibold mq450:text-base">
            Tenants
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[13px] text-sm text-dark-gray">
            <div
              className="self-stretch relative cursor-pointer"
              onClick={onHowItWorksClick}
            >
              How it works
            </div>
            <div className="relative">Refer your friends</div>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-6">
          <div className="relative font-semibold mq450:text-base">
            Landlords
          </div>
          <div className="flex flex-col items-start justify-start py-0 pl-0 pr-2.5 gap-[13px] text-sm text-dark-gray">
            <div
              className="relative inline-block min-w-[93px] cursor-pointer"
              onClick={onHowItWorksClick1}
            >
              How it works
            </div>
            <div
              className="relative inline-block min-w-[81px] cursor-pointer"
              onClick={onHowItWorksClick1}
            >
              List with us
            </div>
            <div
              className="relative cursor-pointer"
              onClick={onUnistayPlusTextClick}
            >
              Unistay plus
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-6">
          <div className="self-stretch relative font-semibold mq450:text-base">
            Support
          </div>
          <div className="flex flex-col items-start justify-start gap-[13px] text-sm text-dark-gray">
            <div className="relative">Help center</div>
            <div
              className="relative cursor-pointer"
              onClick={onContactTextClick}
            >
              Contact
            </div>
            <div className="relative">Cancellation policy</div>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-6">
          <div className="self-stretch relative font-semibold mq450:text-base">
            Resources
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[13px] text-sm text-dark-gray">
            <a
              className="[text-decoration:none] self-stretch relative text-[inherit] cursor-pointer"
              onClick={onAboutUsTextClick}
            >
              Blog
            </a>
            <div
              className="relative cursor-pointer"
              onClick={onAboutUsTextClick}
            >
              Become an ambassador
            </div>
          </div>
        </div>
      </div>
      <img
        className="self-stretch relative max-w-full overflow-hidden max-h-full"
        alt=""
        src="/divider.svg"
      />
    </div>
  );
};

export default FooterLinks;
