import { FunctionComponent } from "react";
import SearchBar from "./SearchBar";

export type HeroType = {
  className?: string;
};

const Hero: FunctionComponent<HeroType> = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch bg-white overflow-hidden flex flex-row items-start justify-start pt-[15px] pb-[42px] pl-16 pr-[49px] box-border gap-0.5 shrink-0 max-w-full mt-[-1.4px] text-left text-29xl text-black font-sora mq750:pl-8 mq750:pr-6 mq750:box-border mq1100:pb-[27px] mq1100:box-border mq450:pb-5 mq450:box-border ${className}`}
    >
      <div className="h-[770px] flex-1 relative max-w-[calc(100%_-_221px)] mq1100:max-w-full mq450:h-auto mq450:min-h-[770]">
        <div className="absolute top-[163px] left-[0px] w-[630px] flex flex-col items-start justify-start gap-[25px] max-w-full">
          <div className="self-stretch flex flex-row items-center justify-center">
            <h1 className="m-0 flex-1 relative text-inherit font-semibold font-[inherit] mq750:text-19xl mq450:text-10xl">
              <p className="m-0">Find Your Perfect</p>
              <p className="m-0">
                <span>{`Student Accommodation In `}</span>
                <span className="text-blue">Minutes</span>
              </p>
            </h1>
          </div>
          <div className="w-[554px] flex flex-row items-center justify-center max-w-full text-lg text-dark-gray">
            <div className="flex-1 relative inline-block max-w-full">
              Say goodbye to overwhelming housing searches. Discover options
              that fit your lifestyle and budget, smoothing your journey to a
              comfortable new home
            </div>
          </div>
          <SearchBar />
        </div>
        <div className="absolute h-full top-[0px] bottom-[0px] left-[559px] w-[547px]">
          <img
            className="absolute top-[471px] left-[280px] rounded-[50%] w-[227px] h-[227px] object-cover"
            loading="lazy"
            alt=""
            src="/hero-image-2@2x.png"
          />
          <img
            className="absolute top-[280px] left-[97px] rounded-[50%] w-[199px] h-[199px] object-cover z-[1]"
            alt=""
            src="/hero-image-4@2x.png"
          />
          <img
            className="absolute top-[245px] left-[342px] rounded-[50%] w-[194px] h-[194px] object-cover"
            loading="lazy"
            alt=""
            src="/hero-image-5@2x.png"
          />
          <img
            className="absolute top-[114px] left-[220px] rounded-[50%] w-[142px] h-[142px] object-cover z-[1]"
            alt=""
            src="/hero-image-7@2x.png"
          />
          <img
            className="absolute top-[69px] left-[409px] rounded-[50%] w-[138px] h-[138px] object-cover"
            loading="lazy"
            alt=""
            src="/hero-image-8@2x.png"
          />
          <img
            className="absolute top-[0px] left-[313px] rounded-[50%] w-[97px] h-[97px] object-cover z-[1]"
            loading="lazy"
            alt=""
            src="/hero-image-9@2x.png"
          />
          <img
            className="absolute top-[505px] left-[0px] rounded-[50%] w-[265px] h-[265px] object-cover z-[1]"
            loading="lazy"
            alt=""
            src="/hero-image-1@2x.png"
          />
        </div>
      </div>
      <div className="flex flex-col items-start justify-start pt-[138px] px-0 pb-0 mq750:pt-[15px] mq750:box-border mq1100:hidden">
        <div className="flex flex-col items-start justify-start gap-[45px]">
          <div className="flex flex-row items-start justify-start py-0 px-[21px]">
            <img
              className="h-[166px] w-[166px] relative rounded-[50%] object-cover"
              loading="lazy"
              alt=""
              src="/hero-image-6@2x.png"
            />
          </div>
          <img
            className="w-[219px] h-[219px] relative rounded-[50%] object-cover"
            loading="lazy"
            alt=""
            src="/hero-image-3@2x.png"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
