import { FunctionComponent, useCallback } from "react";

export type HowItWorks1Type = {
  className?: string;
};

const HowItWorks1: FunctionComponent<HowItWorks1Type> = ({
  className = "",
}) => {
  const onHowItWorksClick = useCallback(() => {
    // Please sync "How it works - tenants" to the project
  }, []);

  return (
    <a
      className={`[text-decoration:none] relative text-sm font-semibold font-sora text-black text-left cursor-pointer ${className}`}
      onClick={onHowItWorksClick}
    >
      How It Works
    </a>
  );
};

export default HowItWorks1;
