import { FunctionComponent } from "react";
import SearchIcon from "./SearchIcon";

export type SearchBarType = {
  className?: string;
};

const SearchBar: FunctionComponent<SearchBarType> = ({ className = "" }) => {
  return (
    <div
      className={`w-[502px] shadow-[0px_2px_2px_rgba(0,_0,_0,_0.25)] rounded-11xl bg-white border-light-gray border-[1px] border-solid box-border flex flex-row items-center justify-center flex-wrap content-center py-4 px-8 gap-0 max-w-full [row-gap:20px] text-left text-base text-medium-grey font-sora ${className}`}
    >
      <div className="flex-1 flex flex-row items-center justify-start min-w-[225px] max-w-full">
        <div className="relative">Search by University or City</div>
      </div>
      <SearchIcon />
    </div>
  );
};

export default SearchBar;
