import { FunctionComponent } from "react";
import Solution from "./Solution";

export type WhyUsType = {
  className?: string;
};

const WhyUs: FunctionComponent<WhyUsType> = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch bg-white overflow-hidden flex flex-col items-start justify-start pt-[78px] pb-[198px] pl-16 pr-[71px] box-border gap-[71px] shrink-0 max-w-full z-[4] mt-[-1.4px] text-center text-13xl text-black font-sora mq750:gap-[35px] mq750:pl-8 mq750:pr-[35px] mq750:pb-[84px] mq750:box-border mq1100:pb-[129px] mq1100:box-border mq450:gap-[18px] ${className}`}
    >
      <div className="flex flex-col items-start justify-start gap-3 max-w-full">
        <div className="flex flex-row items-center justify-start">
          <h1 className="m-0 relative text-inherit font-semibold font-[inherit] mq750:text-7xl mq450:text-lgi">
            <span>{`Helping `}</span>
            <span className="text-mediumseagreen">Nigerian</span>
            <span>{` `}</span>
            <span className="text-mediumseagreen">students,</span>
            <span> find a new home</span>
          </h1>
        </div>
        <div className="self-stretch flex flex-row items-center justify-start text-base">
          <div className="relative">
            Tailored Solutions for Hassle-Free Student Housing
          </div>
        </div>
      </div>
      <div className="self-stretch h-[523px] flex flex-row items-start justify-between min-w-[320px] text-left text-xl">
        <div className="w-[306.5px] flex flex-col items-start justify-start gap-[72px] mq450:gap-9">
          <div className="self-stretch flex flex-col items-start justify-center pt-[9px] px-0 pb-0 gap-[18px]">
            <div className="flex flex-row items-start justify-center">
              <img
                className="h-10 w-10 relative object-cover"
                loading="lazy"
                alt=""
                src="/apartment@2x.png"
              />
            </div>
            <div className="flex flex-row items-center justify-center">
              <div className="relative font-semibold mq450:text-base">
                Extensive Property Selection
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-center text-base">
              <div className="flex-1 relative">
                Explore a diverse range of student accommodations near
                universities across Nigeria. From cozy private rooms to shared
                apartments, Unistay offers options to suit every preference and
                budget
              </div>
            </div>
          </div>
          <Solution
            headset="/headset@2x.png"
            customerSupport="24/7 Customer Support"
            enjoyRoundTheClockCustomer="Enjoy round-the-clock customer support whenever you need assistance. Unistay's dedicated support team is ready to address your inquiries and ensure a smooth user experience"
          />
        </div>
        <div className="w-[306.5px] flex flex-col items-start justify-start gap-[71px] mq450:gap-[35px]">
          <Solution
            propPadding="unset"
            headset="/test@2x.png"
            customerSupport="Seamless Lease Process"
            enjoyRoundTheClockCustomer="Experience a seamless lease process with Unistay's step-by-step guidance. From negotiating lease terms to signing agreements, Unistay streamlines the process for a stress-free move-in."
          />
          <Solution
            propPadding="0px 19px 0px 0px"
            headset="/lock@2x.png"
            customerSupport={`Safe & Secure Transactions`}
            enjoyRoundTheClockCustomer="Rest assured with secure transactions and payment processing. Unistay prioritizes the safety of your financial information, providing a secure platform for all transactions."
          />
        </div>
        <div className="w-[306.5px] flex flex-col items-start justify-start gap-[71px] mq450:gap-[35px]">
          <Solution
            propPadding="unset"
            headset="/verified-account@2x.png"
            customerSupport="Verified Listings"
            enjoyRoundTheClockCustomer="Rest assured with verified property listings and trustworthy landlords. Unistay ensures that all listings undergo thorough verification, creating a secure and reliable housing environment"
          />
          <Solution
            propPadding="unset"
            headset="/people-working-together@2x.png"
            customerSupport="Thriving Student Community"
            enjoyRoundTheClockCustomer="Become part of a vibrant student community with Unistay. Connect with fellow students, participate in engaging events, and experience a sense of belonging in your new home away from home"
          />
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
