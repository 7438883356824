import { FunctionComponent } from "react";

export type ArrowType = {
  className?: string;
};

const Arrow: FunctionComponent<ArrowType> = ({ className = "" }) => {
  return (
    <div
      className={`flex flex-col items-start justify-start p-2.5 ${className}`}
    >
      <img
        className="w-5 h-5 relative overflow-hidden shrink-0"
        alt=""
        src="/eparrowdown.svg"
      />
    </div>
  );
};

export default Arrow;
