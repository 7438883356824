import { FunctionComponent } from "react";

export type PropertyListingType = {
  className?: string;
  propertyImage?: string;
};

const PropertyListing: FunctionComponent<PropertyListingType> = ({
  className = "",
  propertyImage,
}) => {
  return (
    <div
      className={`w-[302px] shadow-[0px_2px_2px_rgba(0,_0,_0,_0.25)] rounded-8xs bg-white border-light-gray border-[1px] border-solid box-border overflow-hidden shrink-0 flex flex-col items-start justify-start py-4 pl-3 pr-[13px] gap-5 min-w-[287px] text-left text-lg text-black font-sora ${className}`}
    >
      <img
        className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src={propertyImage}
      />
      <div className="flex flex-col items-start justify-start gap-2">
        <div className="relative font-semibold">Crystal Lodge, Hilltop</div>
        <div className="flex flex-col items-start justify-start gap-2 text-mini">
          <div className="relative">Nsukka, Enugu</div>
          <div className="relative">{`Self-Contained Apartment `}</div>
        </div>
      </div>
      <div className="flex flex-row items-start justify-start text-base">
        <div className="relative">
          <span className="font-semibold">NGN 580,000</span>
          <span className="text-sm text-dark-gray">/year</span>
        </div>
      </div>
    </div>
  );
};

export default PropertyListing;
