import { FunctionComponent } from "react";

export type SignInType = {
  className?: string;
};

const SignIn: FunctionComponent<SignInType> = ({ className = "" }) => {
  return (
    <a
      className={`[text-decoration:none] relative text-sm font-semibold font-sora text-black text-left inline-block min-w-[51px] ${className}`}
    >
      Sign in
    </a>
  );
};

export default SignIn;
