import { FunctionComponent } from "react";
import Header from "./Header";
import FooterLinks from "./FooterLinks";

export type FooterType = {
  className?: string;
};

const Footer: FunctionComponent<FooterType> = ({ className = "" }) => {
  return (
    <div
      className={`self-stretch bg-white overflow-hidden flex flex-col items-start justify-start py-10 px-16 box-border gap-12 max-w-full text-left text-xl text-black font-sora mq750:gap-6 mq750:py-[26px] mq750:px-8 mq750:box-border ${className}`}
    >
      <Header />
      <FooterLinks />
      <div className="self-stretch flex flex-row items-start justify-start flex-wrap content-start gap-0 max-w-full [row-gap:20px] text-base">
        <div className="flex-1 flex flex-row items-center justify-center min-w-[694px] max-w-full mq1100:min-w-full">
          <div className="flex-1 relative inline-block max-w-full">
            Copyright © 2023 Unistay, All rights reserved
          </div>
        </div>
        <div className="flex flex-row items-start justify-start gap-[30px]">
          <div className="relative">Terms of use</div>
          <a className="[text-decoration:none] relative text-[inherit]">
            Privacy policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
