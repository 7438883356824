import { FunctionComponent, useCallback } from "react";

export type FindAccommodationType = {
  className?: string;
};

const FindAccommodation: FunctionComponent<FindAccommodationType> = ({
  className = "",
}) => {
  const onFindAccommodationTextClick = useCallback(() => {
    // Please sync "Listings/Search" to the project
  }, []);

  return (
    <a
      className={`[text-decoration:none] relative text-sm font-semibold font-sora text-black text-left cursor-pointer ${className}`}
      onClick={onFindAccommodationTextClick}
    >
      Find Accommodation
    </a>
  );
};

export default FindAccommodation;
