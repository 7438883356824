import { FunctionComponent } from "react";
import Footer from "./Footer";

export type FooterSectionType = {
  className?: string;
};

const FooterSection: FunctionComponent<FooterSectionType> = ({
  className = "",
}) => {
  return (
    <footer
      className={`self-stretch flex flex-col items-start justify-start max-w-full text-left text-xl text-black font-sora ${className}`}
    >
      <Footer />
    </footer>
  );
};

export default FooterSection;
