import { FunctionComponent } from "react";

export type GetStartedType = {
  className?: string;
};

const GetStarted: FunctionComponent<GetStartedType> = ({ className = "" }) => {
  return (
    <a
      className={`[text-decoration:none] relative text-sm font-semibold font-sora text-white text-left ${className}`}
    >
      Get started
    </a>
  );
};

export default GetStarted;
