import { FunctionComponent } from "react";

export type HowItWorksType = {
  className?: string;
};

const HowItWorks: FunctionComponent<HowItWorksType> = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch bg-unistay-blue-low overflow-hidden flex flex-col items-start justify-start pt-[130.2px] pb-[211.5px] pl-[236px] pr-[203px] box-border relative gap-[447.5px] shrink-0 max-w-full z-[2] mt-[-1.4px] text-left text-5xl text-blue font-sora mq750:gap-56 mq750:pl-[118px] mq750:pr-[101px] mq750:box-border mq1100:pb-[89px] mq1100:box-border mq1275:pb-[137px] mq1275:box-border mq450:gap-28 mq450:pl-5 mq450:pr-5 mq450:pb-[58px] mq450:box-border ${className}`}
    >
      <img
        className="w-[461.6px] h-[389.3px] !m-[0] absolute top-[290px] left-[222px] rounded-11xl overflow-hidden shrink-0 object-contain"
        loading="lazy"
        alt=""
        src="/search-and-filter@2x.png"
      />
      <div className="w-[966.9px] flex flex-row items-start justify-start pt-0 px-[162px] pb-[51.6px] box-border max-w-full shrink-0 text-center text-21xl text-black mq750:pl-10 mq750:pr-10 mq750:box-border mq1100:pl-[81px] mq1100:pr-[81px] mq1100:box-border">
        <h1 className="m-0 flex-1 relative text-inherit font-semibold font-[inherit] mq750:text-13xl mq450:text-5xl">
          <span>{`Your Dream Home: `}</span>
          <span className="text-blue">3 Easy Steps</span>
        </h1>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start relative max-w-full">
        <img
          className="h-[334.5px] w-[280.9px] absolute !m-[0] top-[-217px] right-[238.6px] object-contain"
          loading="lazy"
          alt=""
          src="/next-step-line-1.svg"
        />
        <div className="flex-1 flex flex-col items-start justify-start gap-[98.7px] max-w-full mq750:gap-[25px] mq1100:gap-[49px]">
          <div className="flex flex-row items-start justify-start gap-2">
            <div className="flex flex-col items-start justify-start pt-[2.5px] px-0 pb-0">
              <h3 className="m-0 relative text-inherit font-semibold font-[inherit] inline-block min-w-[111px] mq450:text-lgi">
                Discover
              </h3>
            </div>
            <h1 className="m-0 h-[35px] relative text-16xl font-semibold font-[inherit] inline-block min-w-[36px] mq750:text-9xl mq450:text-2xl">
              👀
            </h1>
          </div>
          <div className="self-stretch flex flex-row items-start justify-end max-w-full">
            <div className="w-[590.5px] flex flex-row items-center justify-start gap-[41px] max-w-full z-[2] mq750:gap-5 mq750:flex-wrap">
              <div className="flex flex-row items-center justify-start py-5 px-0 gap-2">
                <div className="flex flex-row items-end justify-end py-0 pl-1.5 pr-0">
                  <h3 className="m-0 relative text-inherit font-semibold font-[inherit] mq450:text-lgi">
                    Pay rent
                  </h3>
                </div>
                <div className="overflow-hidden flex flex-col items-start justify-start p-0.5">
                  <img
                    className="w-[30.6px] h-[19.6px] relative"
                    loading="lazy"
                    alt=""
                    src="/group.svg"
                  />
                </div>
              </div>
              <form className="m-0 flex-1 shadow-[0px_2px_2px_rgba(0,_0,_0,_0.25)] rounded-11xl bg-white flex flex-col items-center justify-center p-8 box-border gap-4 min-w-[260px] max-w-full">
                <div className="self-stretch flex flex-row items-center justify-start">
                  <div className="relative text-base leading-[100%] font-semibold font-sora text-black text-center">
                    Fill in card information
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-end gap-4">
                  <div className="self-stretch flex flex-col items-start justify-start gap-2">
                    <div className="flex flex-row items-start justify-start">
                      <div className="relative text-3xs font-sora text-black text-left inline-block min-w-[68px]">
                        Card number
                      </div>
                    </div>
                    <div className="self-stretch h-[45px] rounded-8xs border-light-gray border-[1px] border-solid box-border overflow-hidden shrink-0 flex flex-row items-center justify-start py-3.5 px-3">
                      <div className="relative text-3xs font-sora text-medium-grey text-left">
                        0000 0000 0000 0000
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-end justify-start gap-[25px] mq450:flex-wrap">
                    <div className="flex-1 flex flex-col items-start justify-start gap-2 min-w-[101px]">
                      <div className="flex flex-row items-start justify-start">
                        <div className="relative text-3xs font-sora text-black text-left inline-block min-w-[60px]">
                          Card expiry
                        </div>
                      </div>
                      <div className="self-stretch h-[45px] rounded-8xs border-light-gray border-[1px] border-solid box-border overflow-hidden shrink-0 flex flex-row items-center justify-start py-3.5 px-3">
                        <div className="relative text-3xs font-sora text-medium-grey text-left inline-block min-w-[41px]">
                          MM / YY
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col items-start justify-start gap-2 min-w-[101px]">
                      <div className="flex flex-row items-start justify-start">
                        <div className="relative text-3xs font-sora text-black text-left inline-block min-w-[24px]">
                          CVV
                        </div>
                      </div>
                      <div className="self-stretch h-[45px] rounded-8xs border-light-gray border-[1px] border-solid box-border overflow-hidden shrink-0 flex flex-row items-center justify-start py-3.5 px-3">
                        <div className="relative text-3xs font-sora text-medium-grey text-left">
                          123
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rounded-8xs bg-blue flex flex-row items-center justify-center py-2.5 px-[133px] mq450:pl-5 mq450:pr-5 mq450:box-border">
                  <div className="relative text-base font-semibold font-sora text-white text-left">
                    Pay now
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="w-[588.5px] !m-[0] absolute bottom-[-491.5px] left-[-83.1px] flex flex-row items-start justify-start pt-[524px] px-[223px] pb-[11.5px] box-border max-w-full text-16xl text-black">
          <img
            className="h-[288.1px] w-[379.8px] absolute !m-[0] right-[-367.2px] bottom-[121.1px] object-contain"
            loading="lazy"
            alt=""
            src="/next-step-line-2.svg"
          />
          <img
            className="w-full !m-[0] absolute h-full top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full object-contain z-[1]"
            alt=""
            src="/finalize-step@2x.png"
          />
          <h1 className="m-0 h-[35px] w-9 relative text-inherit font-semibold font-[inherit] inline-block shrink-0 z-[2] mq750:text-9xl mq450:text-2xl">
            🤝🏾
          </h1>
        </div>
      </div>
      <div className="flex flex-row items-start justify-start py-0 px-[35px]">
        <h3 className="m-0 relative text-inherit font-semibold font-[inherit] z-[2] mq450:text-lgi">
          Finalize
        </h3>
      </div>
    </section>
  );
};

export default HowItWorks;
