import { FunctionComponent, useMemo, type CSSProperties } from "react";

export type TsgType = {
  className?: string;
  comingSoon?: string;

  /** Style props */
  propPosition?: CSSProperties["position"];
  propTop?: CSSProperties["top"];
  propLeft?: CSSProperties["left"];
  propBackgroundColor?: CSSProperties["backgroundColor"];
  propPadding?: CSSProperties["padding"];
  propBorderRadius?: CSSProperties["borderRadius"];
  propFlex?: CSSProperties["flex"];
  propColor?: CSSProperties["color"];
  propTextDecoration?: CSSProperties["textDecoration"];
  propDisplay?: CSSProperties["display"];
  propMinWidth?: CSSProperties["minWidth"];

  /** Action props */
  onActionBtnContainerClick?: () => void;
};

const Tsg: FunctionComponent<TsgType> = ({
  className = "",
  onActionBtnContainerClick,
  propPosition,
  propTop,
  propLeft,
  propBackgroundColor,
  propPadding,
  propBorderRadius,
  propFlex,
  comingSoon,
  propColor,
  propTextDecoration,
  propDisplay,
  propMinWidth,
}) => {
  const tsgStyle: CSSProperties = useMemo(() => {
    return {
      position: propPosition,
      top: propTop,
      left: propLeft,
      backgroundColor: propBackgroundColor,
      padding: propPadding,
      borderRadius: propBorderRadius,
      flex: propFlex,
    };
  }, [
    propPosition,
    propTop,
    propLeft,
    propBackgroundColor,
    propPadding,
    propBorderRadius,
    propFlex,
  ]);

  const comingSoonStyle: CSSProperties = useMemo(() => {
    return {
      color: propColor,
      textDecoration: propTextDecoration,
      display: propDisplay,
      minWidth: propMinWidth,
    };
  }, [propColor, propTextDecoration, propDisplay, propMinWidth]);

  return (
    <div
      className={`absolute top-[0px] left-[206px] shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] bg-blue flex flex-row items-start justify-start py-1.5 pl-[34px] pr-[33px] whitespace-nowrap z-[2] text-left text-lg text-white font-sora ${className}`}
      onClick={onActionBtnContainerClick}
      style={tsgStyle}
    >
      <div className="relative font-semibold" style={comingSoonStyle}>
        {comingSoon}
      </div>
    </div>
  );
};

export default Tsg;
