import { FunctionComponent, memo } from "react";

export type ResourcesType = {
  className?: string;
};

const Resources: FunctionComponent<ResourcesType> = memo(
  ({ className = "" }) => {
    return (
      <a
        className={`[text-decoration:none] relative text-sm font-semibold font-sora text-black text-left inline-block min-w-[77px] ${className}`}
      >
        Resources
      </a>
    );
  }
);

export default Resources;
